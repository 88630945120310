<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">매장</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  ref="dropdownlist-storeCode"
                  v-bind="storeCodeDropdownListProps"
                  v-model="selectedStoreCode"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">POS 메뉴</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  ref="dropdownlist-storeCode"
                  v-bind="commonDepthOneProductCategoryCodeDropdownListProps"
                  v-model="selectedCommonDepthOneProductCategoryCodeValueModel"
                />
              </li>
              <li class="item">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  ref="dropdownlist-storeCode"
                  v-bind="commonDepthTwoProductCategoryCodeDropdownListProps"
                  v-model="selectedCommonDepthTwoProductCategoryCodeValueModel"
                />
              </li>
              <li class="item">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  ref="dropdownlist-storeCode"
                  v-bind="commonDepthThreeProductCategoryCodeDropdownListProps"
                  v-model="
                    selectedCommonDepthThreeProductCategoryCodeValueModel
                  "
                />
              </li>
              <li class="item">
                <input-text
                  v-model="searchCommonProductsQuery"
                  @keydown.enter="onSearchCommonProductsClick"
                />
              </li>
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button
                        button-div="GET"
                        @click.native="onSearchCommonProductsClick"
                    >
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              @click.native="onViewButtonClick"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <div class="article-left" style="width: calc(40% - 15px)">
          <section class="article-section">
            <div class="section-body" style="border-top-color: #000">
              <div
                v-if="commonProducts && commonProducts.length !== 0"
                style="padding: 14px"
              >
                <div
                  v-for="(commonProduct, index) in commonProducts"
                  v-bind:key="`common-products-${commonProduct.productCode}`"
                  @click="
                    onCommonProductClick({
                      event: $event,
                      product: commonProduct,
                    })
                  "
                  :style="{
                    boxSizing: 'border-box',
                    float: 'left',
                    padding: '4px',
                    width: 'calc(100% / 3)',
                  }"
                >
                  <div
                    :style="{
                      boxSizing: 'border-box',
                      padding: '8px',
                      borderRadius: '3px',
                      display: 'block',
                      height: '90px',
                      border: '1px solid #ccc',
                      backgroundColor: commonProduct.checked
                        ? '#ecfaf0'
                        : '#fff',
                      cursor: 'pointer',
                    }"
                  >
                    <div>{{ index + 1 }}</div>
                    <div>{{ commonProduct.productName }}</div>
                    <div>{{ commonProduct.productCode }}</div>
                    <div style="text-align: right">
                      {{ commonProduct.price1 | numberWithCommas }}
                    </div>
                  </div>
                </div>
                <div style="clear: both; height: 0"></div>
              </div>
            </div>
          </section>
        </div>
        <div class="article-center" style="width: 29px">
          <section class="article-section section-03">
            <div
              class="section-body"
              style="
                overflow: hidden;
                border: none;
                background-color: transparent;
              "
            >
              <ul class="body-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      @click.native="onRegisterClick"
                  >
                    추가
                  </erp-button>
                </li>
              </ul>
            </div>
          </section>
        </div>
        <div class="article-right" style="float: left; width: calc(60% - 15px)">
          <section class="article-section">
            <div class="section-caption" style="border-top-color: #000">
              <div class="caption-navigation">
                <div>
                  <div
                    v-if="rootProductCategories.length === 0"
                    style="text-align: center; line-height: 33px"
                  >
                    No categories
                  </div>
                  <div v-else>
                    <div
                      :style="{
                        float: 'left',
                      }"
                      v-for="rootProductCategory in rootProductCategories"
                      v-bind:key="`pos-root-category-${rootProductCategory.code}`"
                      @click="
                        onRootProductCategoryClicked({
                          item: rootProductCategory,
                        })
                      "
                    >
                      <div
                        style="
                          padding-top: 3px;
                          border-right: 1px solid #e0e0e0;
                          border-bottom: 1px solid transparent;
                          line-height: 33px;
                        "
                        :style="{
                          padding: '0 12px',
                          cursor:
                            rootProductCategory.code !==
                            selectedRootProductCategoryCode
                              ? 'pointer'
                              : undefined,
                          color:
                            rootProductCategory.code ===
                            selectedRootProductCategoryCode
                              ? '#000'
                              : '#bbb',
                          backgroundColor:
                            rootProductCategory.code ===
                            selectedRootProductCategoryCode
                              ? '#fff'
                              : 'transparent',
                          borderBottomColor:
                            rootProductCategory.code ===
                            selectedRootProductCategoryCode
                              ? '#fff'
                              : 'transparent',
                        }"
                      >
                        {{ rootProductCategory.name }}
                      </div>
                    </div>
                    <div style="clear: both; height: 0"></div>
                  </div>
                </div>
              </div>
              <div class="caption-navigation" style="background-color: #fff">
                <div style="padding: 0 6px">
                  <div
                    v-if="childrenProductCategories.length === 0"
                    style="text-align: center; line-height: 33px"
                  >
                    No categories
                  </div>
                  <div v-else>
                    <div
                      :style="{
                        float: 'left',
                        padding: '0 6px',
                      }"
                      v-for="childrenProductCategory in childrenProductCategories"
                      v-bind:key="`pos-child-category-${childrenProductCategory.code}`"
                      @click="
                        onChildrenProductCategoryClicked({
                          item: childrenProductCategory,
                        })
                      "
                    >
                      <div
                        style="
                          padding-top: 3px;
                          border-bottom: 3px solid transparent;
                          line-height: 28px;
                        "
                        :style="{
                          cursor:
                            childrenProductCategory.code !==
                            selectedChildrenProductCategoryCode
                              ? 'pointer'
                              : undefined,
                          color:
                            childrenProductCategory.code ===
                            selectedChildrenProductCategoryCode
                              ? '#2e7458'
                              : undefined,
                          borderColor:
                            childrenProductCategory.code ===
                            selectedChildrenProductCategoryCode
                              ? '#2e7458'
                              : 'transparent',
                        }"
                      >
                        {{ childrenProductCategory.name }}
                      </div>
                    </div>
                    <div style="clear: both; height: 0"></div>
                  </div>
                </div>
              </div>
              <ul class="caption-button">
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :is-shortcut-button="true"
                      @click.native="onDeleteClick"
                  >
                    삭제
                  </erp-button>
                </li>
              </ul>
            </div>
            <div
              id="table-body"
              :class="[
                'section-body',
                tableInfo.start && 'draggable'
              ]"
              :data-last-area="true"
            >
              <div v-if="storeProducts.length > 0" style="padding: 14px">
                <div
                  v-for="storeProduct in storeProducts" :key="`pos-product-${storeProduct.code}`"
                  @click="
                    onStoreProductClick({
                      event: $event,
                      product: storeProduct,
                    })
                  "
                  :style="{
                    boxSizing: 'border-box',
                    float: 'left',
                    width: 'calc(100% / 5)',
                  }"
                >
                  <div
                    :class="[
                      'drag-wrapper',
                      tableInfo.start && 'draggable'
                    ]"
                  >
                    <div
                      :class="[
                        'divider',
                        tableInfo.end === storeProduct.code && !tableInfo.isNotArea && 'active'
                      ]"
                      :data-code="storeProduct.code"
                    >
                      <div
                        class="line"
                        :data-code="storeProduct.code"
                      />
                    </div>
                    <div
                      :class="[
                        'table',
                        storeProduct.checked && 'checked',
                        tableInfo.end === storeProduct.code && tableInfo.isNotArea && 'active'
                      ]"
                      :data-code="storeProduct.code"
                      :data-not-area="true"
                      @mousedown="onMouseDown"
                    >
                      <div>{{ storeProduct.name }}</div>
                      <div style="position: absolute; right: 8px; bottom: 8px">
                        {{ storeProduct.price | numberWithCommas }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  :style="{
                    boxSizing: 'border-box',
                    float: 'left',
                    width: 'calc(100% / 5)',
                  }"
                >
                  <div
                    :class="[
                      'drag-wrapper',
                      tableInfo.start && 'draggable'
                    ]"
                  >
                    <div
                      :class="[
                        'divider',
                        tableInfo.end === 'LAST_PRODUCT_ITEM' && 'active'
                      ]"
                      data-code="LAST_PRODUCT_ITEM"
                      :data-last-area="true"
                    >
                      <div
                        class="line"
                        data-code="LAST_PRODUCT_ITEM"
                        :data-last-area="true"
                      />
                    </div>
                  </div>
                </div>
                <div style="clear: both; height: 0"></div>
              </div>
            </div>
          </section>
        </div>
      </article>
    </div>
    <div
      v-if="tableInfo.start"
      class="drag-wrapper"
    >
      <div
        class="table fixed"
        :style="{
          left: `${pos.x + 10}px`,
          top: `${pos.y + 10}px`,
        }"
      >
        <div>{{ getTableInfo.name }}</div>
        <div style="position: absolute; right: 8px; bottom: 8px">
          {{ getTableInfo.price | numberWithCommas }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Drag */
.draggable {cursor: move !important;}
.drag-wrapper {display: flex}
.drag-wrapper > .divider {display: flex; align-items: center; justify-content: center; width: 15px; height: 90px; margin-bottom: 8px; z-index: 1}
.drag-wrapper > .divider > .line {display: none; height: 80px; border-left: 1px dashed #333;}
.drag-wrapper > .divider.active > .line {display: flex;}
.drag-wrapper > .table {position: relative; box-sizing: border-box; padding: 8px; border-radius: 3px; display: block; flex: 1; height: 90px; border: 1px solid #ccc; cursor: pointer;}
.drag-wrapper > .table.checked {background: #ecfaf0;}
.drag-wrapper > .table.active {background: rgba(0, 0, 255, .1);}
.drag-wrapper > .table.fixed {width: 170px; position: fixed; left: 0; top: 0; border: 0; border-radius: 0; background: rgba(0, 0, 255, .1); opacity: .75;}

.article-section.section-03 {padding: 9px 3px;}
.article-section.section-03 .section-body {display: flex; align-items: center;}
.article-section.section-03 .body-button {display: block; list-style: none; float: left; padding: 1px 0 0 0; margin: 0;}
.article-section.section-03 .body-button li {padding: 4px 0 5px 0;}
.article-section.section-03 .body-button li .e-btn,
.article-section.section-03 .body-button li .e-btn:hover,
.article-section.section-03 .body-button li .e-btn:focus {box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.06);}
.article-section.section-03 .body-button li .e-btn {display: block; overflow: hidden; width: 23px; height: 23px; padding: 0; border-color: #ccc; border-radius: 3px; background: #fff url('../../../assets/images/common/body-button.png') no-repeat center 0; text-indent: -1000px;}
.article-section.section-03 .body-button li .e-btn:hover,
.article-section.section-03 .body-button li .e-btn:focus {background-color: #f0f0f0;}
.article-section.section-03 .body-button li.delete .e-btn {background-position: center -21px;}
</style>

<script>
import { sortBy as _sortBy, maxBy as _maxBy } from "lodash";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import commonMixin from "@/views/layout/mixin/commonMixin";
import GolfERPService from "@/service/GolfERPService";
import moment from "moment";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import { numberWithCommas } from "@/utils/number";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { getProductList } from "@/api/storePointOfSaleSystem";
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "StoreSalesProductRegistration",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    InputText,
    ErpButton,
  },
  filters: {
    numberWithCommas,
  },
  async created() {
    await this.initialize();
  },
  watch: {
    isLoading(isLoading) {
      if (isLoading) {
        this.$EventBus.$emit("loaderOn");
      } else {
        this.$EventBus.$emit("loaderOff");
      }
    },

    async selectedStoreCode(selectedStoreCode) {
      this.selectedCommonDepthOneProductCategoryCode = "";
      this.commonDepthOneProductCategories = [];
      this.selectedCommonDepthTwoProductCategoryCode = "";
      this.commonDepthTwoProductCategories = [];
      this.selectedCommonDepthThreeProductCategoryCode = "";
      this.commonDepthThreeProductCategories = [];
      this.commonProducts = [];

      this.rootProductCategories = [];
      this.selectedRootProductCategoryCode = "";
      this.childrenProductCategories = [];
      this.selectedChildrenProductCategoryCode = "";
      this.storeProducts = [];

      if (!selectedStoreCode) {
        return;
      }

      await Promise.all([
        this.fetchCommonDepthOneProductCategories(),
        this.fetchCommonProducts(
          selectedStoreCode,
          "",
          "",
          "",
          this.searchCommonProductsQuery
        ),
        this.fetchStoreRootProductCategory(selectedStoreCode),
      ]);
    },
    async selectedCommonDepthOneProductCategoryCode(
      selectedCommonDepthOneProductCategoryCode
    ) {
      this.selectedCommonDepthTwoProductCategoryCode = "";
      this.commonDepthTwoProductCategories = [];
      this.selectedCommonDepthThreeProductCategoryCode = "";
      this.commonDepthThreeProductCategories = [];
      this.commonProducts = [];

      await Promise.all([
        this.fetchCommonDepthTwoProductCategories(
          selectedCommonDepthOneProductCategoryCode
        ),
        this.fetchCommonProducts(
          this.selectedStoreCode,
          selectedCommonDepthOneProductCategoryCode,
          "",
          "",
          this.searchCommonProductsQuery
        ),
      ]);
    },
    async selectedCommonDepthTwoProductCategoryCode(
      selectedCommonDepthTwoProductCategoryCode
    ) {
      this.selectedCommonDepthThreeProductCategoryCode = "";
      this.commonDepthThreeProductCategories = [];
      this.commonProducts = [];

      await Promise.all([
        this.fetchCommonDepthThreeProductCategories(
          selectedCommonDepthTwoProductCategoryCode
        ),
        this.fetchCommonProducts(
          this.selectedStoreCode,
          this.selectedCommonDepthOneProductCategoryCode,
          selectedCommonDepthTwoProductCategoryCode,
          "",
          this.searchCommonProductsQuery
        ),
      ]);
    },
    async selectedCommonDepthThreeProductCategoryCode(
      selectedCommonDepthThreeProductCategoryCode
    ) {
      this.commonProducts = [];

      await this.fetchCommonProducts(
        this.selectedStoreCode,
        this.selectedCommonDepthOneProductCategoryCode,
        this.selectedCommonDepthTwoProductCategoryCode,
        selectedCommonDepthThreeProductCategoryCode,
        this.searchCommonProductsQuery
      );
    },
    async selectedRootProductCategoryCode(selectedRootProductCategoryCode) {
      this.childrenProductCategories = [];
      this.selectedChildrenProductCategoryCode = "";
      this.storeProducts = [];

      if (!selectedRootProductCategoryCode) {
        return;
      }

      await this.fetchStoreChildrenProductCategory(
        this.selectedStoreCode,
        selectedRootProductCategoryCode
      );
    },
    async selectedChildrenProductCategoryCode(
      selectedChildrenProductCategoryCode
    ) {
      this.storeProducts = [];

      if (!selectedChildrenProductCategoryCode) {
        return;
      }

      await this.fetchStoreProducts(
        this.selectedStoreCode,
        selectedChildrenProductCategoryCode,
        this.salesDate
      );
    },

    childrenProductCategories() {
      if (this.reClickedChildrenProductCategoryCode) {
        this.onChildrenProductCategoryClicked({
          item: this.reClickedChildrenProductCategoryCode,
        });
      }
    },
  },
  data() {
    return {
      isLoading: false,

      salesDate: new Date(),

      stores: [],
      selectedStoreCode: "",

      commonDepthOneProductCategories: [],
      selectedCommonDepthOneProductCategoryCode: "",
      commonDepthTwoProductCategories: [],
      selectedCommonDepthTwoProductCategoryCode: "",
      commonDepthThreeProductCategories: [],
      selectedCommonDepthThreeProductCategoryCode: "",

      searchCommonProductsQuery: "",

      commonProducts: [],

      rootProductCategories: [],
      selectedRootProductCategoryCode: "",
      childrenProductCategories: [],
      selectedChildrenProductCategoryCode: "",
      storeProducts: [],

      reClickedChildrenProductCategoryCode: "",

      tableInfo: {
        start: null,
        end: null,
        isNotArea: null,
        isLastArea: null,
      },
      pos: {
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    selectedCommonDepthOneProductCategoryCodeValueModel: {
      get() {
        return this.selectedCommonDepthOneProductCategoryCode;
      },
      set(selectedCommonDepthOneProductCategoryCode) {
        if (
          selectedCommonDepthOneProductCategoryCode === undefined ||
          selectedCommonDepthOneProductCategoryCode === null
        ) {
          return;
        }

        this.selectedCommonDepthOneProductCategoryCode = selectedCommonDepthOneProductCategoryCode;
      },
    },
    selectedCommonDepthTwoProductCategoryCodeValueModel: {
      get() {
        return this.selectedCommonDepthTwoProductCategoryCode;
      },
      set(selectedCommonDepthTwoProductCategoryCode) {
        if (
          selectedCommonDepthTwoProductCategoryCode === undefined ||
          selectedCommonDepthTwoProductCategoryCode === null
        ) {
          return;
        }

        this.selectedCommonDepthTwoProductCategoryCode = selectedCommonDepthTwoProductCategoryCode;
      },
    },
    selectedCommonDepthThreeProductCategoryCodeValueModel: {
      get() {
        return this.selectedCommonDepthThreeProductCategoryCode;
      },
      set(selectedCommonDepthThreeProductCategoryCode) {
        if (
          selectedCommonDepthThreeProductCategoryCode === undefined ||
          selectedCommonDepthThreeProductCategoryCode === null
        ) {
          return;
        }

        this.selectedCommonDepthThreeProductCategoryCode = selectedCommonDepthThreeProductCategoryCode;
      },
    },
    storeCodeDropdownListProps() {
      const storeCodes = this.stores.map(({ code }) => code);

      return {
        dataSource: _sortBy(
          commonCodesGetCommonCode("STORE_CODE").filter(({ comCode }) =>
            storeCodes.includes(comCode)
          ),
          "sortNo"
        ),
        fields: { text: "comName", value: "comCode" },
      };
    },
    commonDepthOneProductCategoryCodeDropdownListProps() {
      const dataSource = this.commonDepthOneProductCategories.map(
        (category) => ({
          name: category.name,
          value: category.code,
        })
      );

      dataSource.unshift({
        name: "전체",
        value: "",
      });

      return {
        dataSource,
        fields: { text: "name", value: "value" },
      };
    },
    commonDepthTwoProductCategoryCodeDropdownListProps() {
      const dataSource = this.commonDepthTwoProductCategories.map(
        (category) => ({
          name: category.name,
          value: category.code,
        })
      );

      dataSource.unshift({
        name: "전체",
        value: "",
      });

      return {
        dataSource,
        fields: { text: "name", value: "value" },
      };
    },
    commonDepthThreeProductCategoryCodeDropdownListProps() {
      const dataSource = this.commonDepthThreeProductCategories.map(
        (category) => ({
          name: category.name,
          value: category.code,
        })
      );

      dataSource.unshift({
        name: "전체",
        value: "",
      });

      return {
        dataSource,
        fields: { text: "name", value: "value" },
      };
    },
    getTableInfo() {
      const data = this.storeProducts
        ?.find(item => item.code === this.tableInfo.start);
      return {
        name: data?.name,
        price: data?.price,
      };
    },
  },
  methods: {
    async onSearchCommonProductsClick() {
      await this.fetchCommonProducts(
        this.selectedStoreCode,
        this.selectedCommonDepthOneProductCategoryCode,
        this.selectedCommonDepthTwoProductCategoryCode,
        this.selectedCommonDepthThreeProductCategoryCode,
        this.searchCommonProductsQuery
      );
    },
    onCommonProductClick({ product }) {
      const target = this.commonProducts.find(
        ({ productCode }) => productCode === product.productCode
      );

      target.checked = !target.checked;
    },
    async onRootProductCategoryClicked({ item }) {
      this.selectedRootProductCategoryCode = item.code;
    },
    async onChildrenProductCategoryClicked({ item }) {
      this.selectedChildrenProductCategoryCode = item.code;
    },
    onStoreProductClick({ product }) {
      const target = this.storeProducts.find(
        ({ code }) => code === product.code
      );

      target.checked = !target.checked;
    },
    async onRegisterClick() {
      const addingProducts = this.commonProducts.filter(
          ({ checked }) => checked
      );
      if (!addingProducts || addingProducts.length === 0) {
        return;
      }

      await this.registerStoreProducts(this.selectedStoreCode, addingProducts);
      if (this.selectedRootProductCategoryCode) {
        await this.onRootProductCategoryClicked({
          item: { code: this.selectedRootProductCategoryCode }
        });
        if (this.selectedChildrenProductCategoryCode) {
          this.reClickedChildrenProductCategoryCode = this.selectedChildrenProductCategoryCode;
        }
      }
      this.infoToast("등록되었습니다");
      await this.refresh();
    },
    async onDeleteClick() {
      const deletingProducts = this.storeProducts.filter(
          ({ checked }) => checked
      );
      if (!deletingProducts || deletingProducts.length === 0) {
        return;
      }

      await this.removeStoreProducts(this.selectedStoreCode, deletingProducts);
      this.infoToast(this.$t("main.popupMessage.deleted"));
      // await this.refresh();

      if (this.selectedRootProductCategoryCode) {
        await this.onRootProductCategoryClicked({
          item: { code: this.selectedRootProductCategoryCode }
        });
        if (this.selectedChildrenProductCategoryCode) {
          this.reClickedChildrenProductCategoryCode = this.selectedChildrenProductCategoryCode;
        }
      }
      await this.refresh();
    },

    async initialize() {
      await Promise.all([this.fetchNow(), this.fetchStores()]);
    },
    async refresh() {
      const fetchCommonArea = await Promise.all([
        this.fetchCommonDepthOneProductCategories(),
        this.fetchCommonDepthTwoProductCategories(
          this.selectedCommonDepthOneProductCategoryCode
        ),
        this.fetchCommonDepthThreeProductCategories(
          this.selectedCommonDepthTwoProductCategoryCode
        ),
        this.fetchCommonProducts(
          this.selectedStoreCode,
          this.selectedCommonDepthOneProductCategoryCode,
          this.selectedCommonDepthTwoProductCategoryCode,
          this.selectedCommonDepthThreeProductCategoryCode,
          this.searchCommonProductsQuery
        ),
      ]);
      const fetchStoreArea = async () => {
        await this.fetchStoreRootProductCategory(this.selectedStoreCode);
        await this.fetchStoreChildrenProductCategory(
          this.selectedStoreCode,
          this.selectedRootProductCategoryCode
        );
        await this.fetchStoreProducts(
          this.selectedStoreCode,
            this.selectedChildrenProductCategoryCode ||
            this.reClickedChildrenProductCategoryCode,
          this.salesDate
        );
      };

      await Promise.all([fetchCommonArea, fetchStoreArea()]);
    },

    // API
    async fetchNow() {
      this.salesDate = (await GolfERPService.fetchNow()).toDate();
    },
    async fetchCommonDepthOneProductCategories() {
      this.commonDepthOneProductCategories = _sortBy(
        (await GolfERPService.fetchProductClasses(1)) || [],
        "order"
      );
    },
    async fetchCommonDepthTwoProductCategories(parentCategoryCode) {
      this.commonDepthTwoProductCategories = _sortBy(
        (parentCategoryCode
          ? await GolfERPService.fetchProductClasses(2, parentCategoryCode)
          : []) || [],
        "order"
      );
    },
    async fetchCommonDepthThreeProductCategories(parentCategoryCode) {
      this.commonDepthThreeProductCategories = _sortBy(
        (parentCategoryCode
          ? await GolfERPService.fetchProductClasses(3, parentCategoryCode)
          : []) || [],
        "order"
      );
    },
    async fetchCommonProducts(
      storeCode,
      depth01Code,
      depth02Code,
      depth03Code,
      productNameCode
    ) {
      const {
        value: { productCodes },
      } = await getProductList(
        storeCode,
        depth01Code || undefined,
        depth02Code || undefined,
        depth03Code || undefined,
        productNameCode || undefined,
        undefined,
        true
      );

      this.commonProducts = _sortBy(
        (productCodes || []).map((product) => ({
          ...product,
          checked: false,
        })),
        "productName"
      );
    },
    async fetchStores() {
      const stores = (await GolfERPService.fetchStores(true)) || [];

      const storeCodes = stores.map(({ code }) => code);
      const selectStore =
        stores.find(
          ({ code }) =>
            code ===
            _sortBy(
              commonCodesGetCommonCode("STORE_CODE").filter(({ comCode }) =>
                storeCodes.includes(comCode)
              ),
              "sortNo"
            )[0].comCode
        ) || {};

      this.stores = stores;
      this.selectedStoreCode = selectStore.code;
    },
    async fetchStoreRootProductCategory(storeCode) {
      const rootProductCategories = storeCode
        ? _sortBy(
            storeCode
              ? (await GolfERPService.fetchStoreRootProductCategory(
                  storeCode
                )) || []
              : [],
            "order"
          )
        : [];

      this.rootProductCategories = rootProductCategories;
      this.selectedRootProductCategoryCode =
        rootProductCategories.findIndex(data => data.code === this.selectedRootProductCategoryCode) === -1 ?
          rootProductCategories[0]?.code || "" : this.selectedRootProductCategoryCode;
    },
    async fetchStoreChildrenProductCategory(storeCode, parentCategoryCode) {
      const childrenProductCategories = _sortBy(
        storeCode && parentCategoryCode
          ? (await GolfERPService.fetchStoreChildrenProductCategory(
              storeCode,
              parentCategoryCode
            )) || []
          : [],
        "order"
      );

      this.childrenProductCategories = childrenProductCategories;
      this.selectedChildrenProductCategoryCode =
        childrenProductCategories.findIndex(data => data.code === this.selectedChildrenProductCategoryCode) === -1 ?
          childrenProductCategories[0]?.code || "" : this.selectedChildrenProductCategoryCode;
    },
    async fetchStoreProducts(storeCode, categoryCode, salesDate) {
      this.storeProducts = _sortBy((storeCode && categoryCode
        ? (await GolfERPService.fetchStoreProducts(
            storeCode,
            categoryCode,
            moment(salesDate).format(DATE_FORMAT_YYYY_MM_DD)
          )) || []
        : []
      ).map((product) => ({
        ...product,
        checked: false,
      })), 'order');
    },
    async registerStoreProducts(storeCode, products) {
      const maxOrder = (_maxBy(this.storeProducts, "order") || { order: 0 })
        .order;

      await GolfERPService.registerStoreProducts(
        storeCode,
        products.map(({ productCode }, index) => ({
          productCode,
          order: maxOrder + index + 1,
        }))
      );

      this.commonProducts.forEach((product) => {
        product.checked = false;
      });
    },
    async removeStoreProducts(storeCode, products) {
      await GolfERPService.removeStoreProducts(
        storeCode,
        products.map(({ code }) => ({
          productCode: code,
        }))
      );
    },
    onMouseDown(event) {
      if (this.commonMixinIsButtonDisableByAuth('codeMngRegStoreProductReg')) {
        return;
      }
      event.preventDefault();
      document.onmousemove = this.onMouseMove;
      document.onmouseup = this.onMouseUp;
    },
    scrollToTop(el, distance) {
      el.scrollTop += distance;
    },
    onMouseMove(event) {
      event.preventDefault();
      const $el = document.getElementById("table-body");
      const rect = $el.getBoundingClientRect();
      if (event.clientY < rect.top + 100) {
        this.scrollToTop($el, -20);
      } else if (event.clientY > rect.bottom - 100) {
        this.scrollToTop($el, 20);
      }
      const code = event.target.getAttribute("data-code");
      const isNotArea = event.target.getAttribute("data-not-area");
      const isLastArea = event.target.getAttribute("data-last-area");
      if (this.tableInfo.start === null) {
        this.tableInfo.start = code;
      }
      this.tableInfo.end = code;
      this.tableInfo.isNotArea = isNotArea;
      this.tableInfo.isLastArea = isLastArea;
      this.pos = {
        x: event.clientX,
        y: event.clientY,
      };
    },
    async onMouseUp() {
      if (this.tableInfo.start !== this.tableInfo.end) {
        const storeCode = this.selectedStoreCode;
        const categoryCode =
            this.selectedChildrenProductCategoryCode ||
            this.reClickedChildrenProductCategoryCode;
        const startProductCode = this.tableInfo.start;
        const endProductCode =
            this.tableInfo.isLastArea && this.storeProducts.length > 0
                ? this.storeProducts[this.storeProducts.length - 1]?.code
                : this.tableInfo.end;
        const isLastProduct =
            (this.tableInfo.isLastArea &&
                this.tableInfo.end === "LAST_PRODUCT_ITEM") ||
            null;
        this.clearTableInfo();
        if (
            storeCode &&
            categoryCode &&
            startProductCode &&
            endProductCode &&
            startProductCode !== endProductCode
        ) {
          const str = this.storeProducts.findIndex(
              c => c.code === startProductCode
          );
          let end = this.storeProducts.findIndex(
              c => c.code === endProductCode
          );
          const changeOb = this.storeProducts.find(
              c => c.code === startProductCode
          );
          end = str > end ? end : isLastProduct ? end : end - 1;
          this.storeProducts.splice(str, 1);
          this.storeProducts.splice(end, 0, changeOb);
          const storeUseProducts = this.storeProducts.map((data, idx) => {
            return {
              order: idx + 1,
              storeCode: data.storeCode,
              productCode: data.code
            };
          });
          try {
            await GolfErpAPI.productSortNoResetting(
                storeCode,
                storeUseProducts
            );
          } catch (e) {
            this.errorToast(this.$t("main.popupMessage.serverError"));
          }
        }
      } else {
        this.clearTableInfo();
      }
    },
    clearTableInfo() {
      this.tableInfo = {
        start: null,
        end: null,
        isNotArea: null,
        isLastArea: null,
      };
      document.onmouseup = null;
      document.onmousemove = null;
    },
    async onViewButtonClick() {
      await this.refresh();
    }
  },
};
</script>
